// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from '../components/Layout'
import { Box, makeStyles } from '@material-ui/core'
import { ProfileQuery } from '../../types/graphql-types'
import { Title, Chapter } from '../components/Typography'
import { render } from '../utils/render'
import { Timeline } from 'react-twitter-widgets'

const useStyles = makeStyles(_theme => ({
  twitter: { maxWidth: 400 },
}))

const Profile: React.FC<PageProps<ProfileQuery>> = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout path="Profile" title="Profile">
      <Title>Profile</Title>
      <Box mb={2}>{render(data.microcmsIndex?.profile)}</Box>
      <Chapter>Twitter</Chapter>
      <Box className={classes.twitter}>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'syokupannomimi',
          }}
          options={{ height: 600, lang: 'ja' }}
        />
      </Box>
    </Layout>
  )
}

export default Profile

export const query = graphql`
  query Profile {
    microcmsIndex {
      profile
    }
  }
`
